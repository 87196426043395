var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _vm._v(" Assign HIT Type "),
          _c("help-button", { attrs: { name: "QualificationRequirements" } }),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-radio-group",
            {
              staticClass: "mt-0",
              attrs: { fixed: "", top: "", "hide-details": "" },
              model: {
                value: _vm.inputs.createNew,
                callback: function ($$v) {
                  _vm.$set(_vm.inputs, "createNew", $$v)
                },
                expression: "inputs.createNew",
              },
            },
            [
              _c("v-radio", {
                attrs: { label: "Create new HIT type", value: true },
                on: {
                  click: function ($event) {
                    return _vm.clearHITTypeParams()
                  },
                },
              }),
              _c("v-radio", {
                attrs: { label: "Choose from existing HIT type", value: false },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _vm.inputs.createNew
            ? _c(
                "div",
                [
                  _c(
                    "v-simple-table",
                    { staticClass: "mt-4", attrs: { dense: "" } },
                    [
                      _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            { attrs: { width: "300px" } },
                            [
                              _vm._v(" Name "),
                              _c("required-badge"),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "text-caption grey--text" },
                                [
                                  _vm._v(
                                    "(Internally-identifiable name for the HIT Type)"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("validated-text-field", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  filled: "",
                                  rules: [_vm.rules.required],
                                },
                                model: {
                                  value: _vm.inputs.tuttiHITBatchName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inputs,
                                      "tuttiHITBatchName",
                                      $$v
                                    )
                                  },
                                  expression: "inputs.tuttiHITBatchName",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-simple-table",
                    { staticClass: "mt-4", attrs: { dense: "" } },
                    [
                      _c("tbody", [
                        _c("tr", { staticClass: "no-bottom-border" }, [
                          _c(
                            "td",
                            { attrs: { width: "300px" } },
                            [_vm._v("Project "), _c("required-badge")],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("validated-autocomplete", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  items: _vm.projectNames,
                                  rules: [_vm.rules.required],
                                },
                                model: {
                                  value: _vm.inputs.projectName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "projectName", $$v)
                                  },
                                  expression: "inputs.projectName",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td"),
                          _c(
                            "td",
                            [
                              _c("rebuild-alert", {
                                attrs: {
                                  type: _vm.alertType,
                                  client: _vm.client,
                                  "project-name": _vm.inputs.projectName,
                                },
                                on: {
                                  "rebuild-finish": function ($event) {
                                    return _vm.checkProjectDiff(
                                      _vm.inputs.projectName
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-subtitle",
                    [
                      _c("b", [_vm._v("HIT Type Params")]),
                      _vm._v(" "),
                      _c("help-button", { attrs: { name: "CreateHITType" } }),
                    ],
                    1
                  ),
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    _vm.HITTypeParamOptions,
                                    function (option) {
                                      return _c("tr", { key: option.name }, [
                                        _c(
                                          "td",
                                          { attrs: { width: "300px" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.name) + " "
                                            ),
                                            option.name !== "Keywords"
                                              ? _c("required-badge")
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          [
                                            "AutoApprovalDelayInSeconds",
                                            "AssignmentDurationInSeconds",
                                          ].indexOf(option.name) > -1
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "validated-text-field",
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          rules: option.rules,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .hitTypeParams[
                                                              option.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs
                                                                .hitTypeParams,
                                                              option.name,
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.hitTypeParams[option.name]",
                                                        },
                                                      },
                                                      "validated-text-field",
                                                      option.attrs,
                                                      false
                                                    )
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.secondsToFormattedTime(
                                                          _vm.inputs
                                                            .hitTypeParams[
                                                            option.name
                                                          ],
                                                          [
                                                            "weeks",
                                                            "days",
                                                            "hrs",
                                                            "mins",
                                                            "secs",
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "validated-text-field",
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          rules: option.rules,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .hitTypeParams[
                                                              option.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs
                                                                .hitTypeParams,
                                                              option.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.hitTypeParams[option.name]",
                                                        },
                                                      },
                                                      "validated-text-field",
                                                      option.attrs,
                                                      false
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  _vm._l(
                                    _vm.inputs.hitTypeParams
                                      .QualificationRequirements,
                                    function (qualItem, qualIndex) {
                                      return _c(
                                        "tr",
                                        {
                                          key:
                                            "QualificationRequirements-" +
                                            qualIndex,
                                        },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              "QualificationRequirements - " +
                                                _vm._s(qualIndex + 1)
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "v-simple-table",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "tbody",
                                                    [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          [
                                                            _vm._v(
                                                              "QualificationTypeId "
                                                            ),
                                                            _c(
                                                              "required-badge"
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "validated-autocomplete",
                                                              {
                                                                attrs: {
                                                                  items:
                                                                    _vm.allQualIds,
                                                                  rules: [
                                                                    _vm.rules
                                                                      .required,
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    qualItem[
                                                                      "QualificationTypeId"
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        qualItem,
                                                                        "QualificationTypeId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "qualItem['QualificationTypeId']",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          [
                                                            _vm._v(
                                                              "Comparator "
                                                            ),
                                                            _c(
                                                              "required-badge"
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "validated-select",
                                                              {
                                                                attrs: {
                                                                  items:
                                                                    _vm
                                                                      .qualRequirementOptions[
                                                                      "Comparator"
                                                                    ],
                                                                  rules: [
                                                                    _vm.rules
                                                                      .required,
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    qualItem[
                                                                      "Comparator"
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        qualItem,
                                                                        "Comparator",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "qualItem['Comparator']",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            "IntegerValues"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "validated-combobox",
                                                              {
                                                                attrs: {
                                                                  multiple: "",
                                                                  "small-chips":
                                                                    "",
                                                                  "append-icon":
                                                                    "",
                                                                  rules: [
                                                                    _vm.rules
                                                                      .numbers,
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    qualItem[
                                                                      "IntegerValues"
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        qualItem,
                                                                        "IntegerValues",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "qualItem['IntegerValues']",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _vm._l(
                                                        qualItem.LocaleValues,
                                                        function (
                                                          localeItem,
                                                          localeIndex
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key:
                                                                "LocaleValues-" +
                                                                localeIndex,
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "LocaleValues - " +
                                                                    _vm._s(
                                                                      localeIndex +
                                                                        1
                                                                    )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "v-simple-table",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "default",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "tbody",
                                                                                    [
                                                                                      _c(
                                                                                        "tr",
                                                                                        [
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Country "
                                                                                              ),
                                                                                              _c(
                                                                                                "required-badge"
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _c(
                                                                                                "validated-text-field",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      rules:
                                                                                                        [
                                                                                                          _vm
                                                                                                            .rules
                                                                                                            .required,
                                                                                                        ],
                                                                                                    },
                                                                                                  model:
                                                                                                    {
                                                                                                      value:
                                                                                                        localeItem.Country,
                                                                                                      callback:
                                                                                                        function (
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            localeItem,
                                                                                                            "Country",
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                      expression:
                                                                                                        "localeItem.Country",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "tr",
                                                                                        [
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Subdivision "
                                                                                              ),
                                                                                              _c(
                                                                                                "required-badge"
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _c(
                                                                                                "validated-text-field",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      rules:
                                                                                                        [
                                                                                                          _vm
                                                                                                            .rules
                                                                                                            .required,
                                                                                                        ],
                                                                                                    },
                                                                                                  model:
                                                                                                    {
                                                                                                      value:
                                                                                                        localeItem.Subdivision,
                                                                                                      callback:
                                                                                                        function (
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            localeItem,
                                                                                                            "Subdivision",
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                      expression:
                                                                                                        "localeItem.Subdivision",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              colspan: "2",
                                                            },
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getNumLocaleValues(
                                                                    qualItem
                                                                  )
                                                                ) +
                                                                  " LocaleValues"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.pushLocaleValues(
                                                                        qualItem
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.popLocaleValues(
                                                                        qualItem
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          [
                                                            _vm._v(
                                                              "ActionsGuarded "
                                                            ),
                                                            _c(
                                                              "required-badge"
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "validated-select",
                                                              {
                                                                attrs: {
                                                                  items:
                                                                    _vm
                                                                      .qualRequirementOptions[
                                                                      "ActionsGuarded"
                                                                    ],
                                                                  rules: [
                                                                    _vm.rules
                                                                      .required,
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    qualItem[
                                                                      "ActionsGuarded"
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        qualItem,
                                                                        "ActionsGuarded",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "qualItem['ActionsGuarded']",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      { attrs: { colspan: "2" } },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.inputs.hitTypeParams
                                                .QualificationRequirements
                                                ? _vm.inputs.hitTypeParams
                                                    .QualificationRequirements
                                                    .length
                                                : 0
                                            ) + " QualificationRequirement(s)"
                                          ),
                                        ]),
                                        _c("help-button", {
                                          attrs: {
                                            "x-small": "",
                                            name: "QualificationRequirements",
                                          },
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushQualRequirements()
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-plus")]
                                        ),
                                        _vm.inputs.hitTypeParams
                                          .QualificationRequirements.length
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.popQualRequirements()
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-minus")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      724287779
                    ),
                  }),
                ],
                1
              )
            : !_vm.inputs.createNew
            ? _c(
                "div",
                [
                  _c(
                    "v-card-subtitle",
                    [
                      _c("b", [_vm._v("Existing HIT Types")]),
                      _vm._v(" "),
                      _c("required-badge"),
                      _vm.rules.required(_vm.inputs.selectedHITBatch) === true
                        ? _c(
                            "v-icon",
                            { attrs: { right: "", color: "success" } },
                            [_vm._v("mdi-check")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c(
                                "tr",
                                { staticStyle: { "background-color": "#eee" } },
                                [
                                  _c("th"),
                                  _c("th", [_vm._v("Name")]),
                                  _c("th", [_vm._v("Title")]),
                                  _c("th", [_vm._v("HIT Type ID")]),
                                  _c("th", [_vm._v("Action")]),
                                ]
                              ),
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm.hitBatches.length === 0
                                  ? _c("tr", [
                                      _c("td", { attrs: { colspan: "5" } }, [
                                        _vm._v("No HIT Type is found."),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._l(_vm.hitBatches, function (hitBatch) {
                                  return _c(
                                    "tr",
                                    { key: "HITType-" + hitBatch.hit_type.id },
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: {
                                                dense: "",
                                                "hide-details": "",
                                                rules: [_vm.rules.required],
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.selectedHITBatch,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs,
                                                    "selectedHITBatch",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.selectedHITBatch",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: { value: hitBatch },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("td", [_vm._v(_vm._s(hitBatch.name))]),
                                      _c("td", [
                                        _vm._v(_vm._s(hitBatch.hit_type.Title)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(hitBatch.hit_type.id)),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                text: "",
                                                color: "indigo",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyToNew(
                                                    hitBatch.hit_type
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Copy to new")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-divider"),
                  _vm.inputs.selectedHITBatch
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-card-subtitle",
                            [
                              _c("b", [_vm._v("HIT Type Params")]),
                              _vm._v(" "),
                              _c("help-button", {
                                attrs: { name: "CreateHITType" },
                              }),
                            ],
                            1
                          ),
                          _c("v-simple-table", {
                            attrs: { dense: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c(
                                        "tbody",
                                        [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { width: "300px" } },
                                              [_vm._v("Project")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inputs.selectedHITBatch
                                                    .project_name
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _vm._l(
                                            _vm.HITTypeParamOptions,
                                            function (option) {
                                              return _c(
                                                "tr",
                                                { key: option.name },
                                                [
                                                  _c("td", [
                                                    _vm._v(_vm._s(option.name)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.inputs
                                                            .selectedHITBatch
                                                            .hit_type[
                                                            option.name
                                                          ]
                                                        ) +
                                                        " "
                                                    ),
                                                    [
                                                      "AutoApprovalDelayInSeconds",
                                                      "AssignmentDurationInSeconds",
                                                    ].indexOf(option.name) > -1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                _vm.secondsToFormattedTime(
                                                                  _vm.inputs
                                                                    .selectedHITBatch
                                                                    .hit_type[
                                                                    option.name
                                                                  ],
                                                                  [
                                                                    "weeks",
                                                                    "days",
                                                                    "hrs",
                                                                    "mins",
                                                                    "secs",
                                                                  ]
                                                                )
                                                              ) +
                                                              ") "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._l(
                                            _vm.dropTuttiHITBatchQualTypes(
                                              _vm.inputs.selectedHITBatch
                                                .hit_type
                                                .QualificationRequirements
                                            ),
                                            function (qualItem, qualIndex) {
                                              return _c(
                                                "tr",
                                                {
                                                  key:
                                                    "QualificationRequirements-" +
                                                    qualIndex,
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      " QualificationRequirements - " +
                                                        _vm._s(qualIndex + 1) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "v-simple-table",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "tbody",
                                                            [
                                                              _vm._l(
                                                                [
                                                                  "QualificationTypeId",
                                                                  "Comparator",
                                                                  "IntegerValues",
                                                                ],
                                                                function (key) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: `inputs.selectedHITBatch-qual-${qualIndex}-${key}`,
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            key
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            qualItem[
                                                                              key
                                                                            ]
                                                                          )
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _vm._l(
                                                                qualItem.LocaleValues,
                                                                function (
                                                                  localeItem,
                                                                  localeIndex
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key:
                                                                        "LocaleValues-" +
                                                                        localeIndex,
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          "LocaleValues - " +
                                                                            _vm._s(
                                                                              localeIndex +
                                                                                1
                                                                            )
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "td",
                                                                        [
                                                                          _c(
                                                                            "v-simple-table",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function () {
                                                                                        return [
                                                                                          _c(
                                                                                            "tbody",
                                                                                            [
                                                                                              _c(
                                                                                                "tr",
                                                                                                [
                                                                                                  _c(
                                                                                                    "td",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Country"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "td",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          localeItem.Country
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "tr",
                                                                                                [
                                                                                                  _c(
                                                                                                    "td",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Subdivision"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "td",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          localeItem.Subdivision
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                      proxy: true,
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c("tr", [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    "ActionsGuarded"
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      qualItem[
                                                                        "ActionsGuarded"
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1692232842
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }