var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "success"
        ? _c(
            "v-alert",
            {
              staticClass: "text-caption",
              attrs: {
                dense: "",
                outlined: "",
                text: "",
                type: "success",
                icon: "mdi-check-circle",
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _vm._v(" Your project seems to be in the newest version. "),
              ]),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("Actions (recommended):")]),
                _c("ul", [
                  _c("li", [
                    _vm._v(" Go to "),
                    _c(
                      "a",
                      { attrs: { href: _vm.url, target: "_blank" } },
                      [
                        _vm._v("this URL"),
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(
                      " and check if your project is already in the newest version and working correctly. This is exactly what MTurk workers see in HITs. "
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm.type === "warning"
        ? _c(
            "v-alert",
            {
              staticClass: "text-caption",
              attrs: {
                dense: "",
                outlined: "",
                text: "",
                type: "warning",
                icon: "mdi-alert",
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("Note:")]),
                _vm._v(
                  " Your project may not be reflecting the newest changes when deployed in MTurk. "
                ),
              ]),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("Actions:")]),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _vm._v(
                        " Rebuild your project (this may take a few minutes)."
                      ),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            outlined: "",
                            color: "warning",
                            loading: _vm.rebuilding,
                          },
                          on: { click: _vm.rebuildProject },
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-wrench"),
                          ]),
                          _vm._v("Rebuild Project Now"),
                        ],
                        1
                      ),
                      _c("br"),
                      _vm._v(
                        " *Wait until this alert disappears. If this keeps loading forever or the alert does not disappear, consult with the customer support. "
                      ),
                    ],
                    1
                  ),
                  _c("li", [
                    _vm._v(" Repeatedly seeing this alert? Go to "),
                    _c(
                      "a",
                      { attrs: { href: _vm.url, target: "_blank" } },
                      [
                        _vm._v("this URL"),
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(
                      " and check if your project is already in the newest version and working correctly. This is exactly what MTurk workers see in HITs."
                    ),
                    _c("br"),
                    _vm._v(
                      " If you are certain that the project has no problem, ignore this alert and proceed. "
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm.type === "error"
        ? _c(
            "v-alert",
            {
              staticClass: "text-caption",
              attrs: {
                dense: "",
                outlined: "",
                text: "",
                type: "error",
                icon: "mdi-alert-decagram",
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("IMPORTANT!")]),
                _vm._v(
                  " Connection to system rebuild server failed. This may result in not properly reflecting changes in projects when deployed in MTurk. "
                ),
              ]),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("Actions:")]),
                _c("ol", [
                  _c("li", [
                    _vm._v(" Go to "),
                    _c(
                      "a",
                      { attrs: { href: _vm.url, target: "_blank" } },
                      [
                        _vm._v("this URL"),
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(
                      " and check if your project is the newest version and working correctly. This is exactly what MTurk workers see in HITs."
                    ),
                    _c("br"),
                    _vm._v(
                      " If you are certain that the project has no problem, ignore this alert and proceed. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(" If your project has problems, run "),
                    _c("code", [_vm._v("./tutti build")]),
                    _vm._v(" and "),
                    _c("code", [_vm._v("./tutti start")]),
                    _vm._v(
                      " from the command line of your Tutti environment, wait for a few minutes, and try the above step again. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Consult with the customer support if the problem is not solved. "
                    ),
                  ]),
                ]),
              ]),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("Why is this happening?")]),
                _c("br"),
                _vm._v(
                  " This is not an issue with your project, but is caused in launching the host web server in Tutti environment. This may also be relevant to your host OS or machine architecture."
                ),
                _c("br"),
                _vm._v(
                  " To remove this alert, here are several tips that you can try: "
                ),
                _c("ul", [
                  _c("li", [
                    _vm._v("Run "),
                    _c("code", [_vm._v("./tutti log host")]),
                    _vm._v(" and see if there are any errors."),
                  ]),
                  _c("li", [
                    _vm._v("Run "),
                    _c("code", [_vm._v("./tutti stop")]),
                    _vm._v(", "),
                    _c("code", [_vm._v("rm .host_api.pid")]),
                    _vm._v(", and "),
                    _c("code", [_vm._v("./tutti start")]),
                    _vm._v("."),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }