var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: { "previous-page-label": "HITs List", title: "Create HITs" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("hit-type-form-card", {
            attrs: { client: _vm.client },
            on: {
              update: _vm.updateHITTypeParams,
              validate: (_valid) => {
                _vm.valid.HITType = _valid
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("hit-form-card", {
            attrs: { client: _vm.client },
            on: {
              update: _vm.updateHITParams,
              validate: (_valid) => {
                _vm.valid.HIT = _valid
              },
            },
          }),
        ],
        1
      ),
      _vm.projectHasDiff
        ? _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-alert",
                { attrs: { border: "left", type: "error" } },
                [
                  _vm._v(
                    " Seems like your project has been updated since last build.     "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "white",
                        outlined: "",
                        loading: _vm.rebuildingProject,
                      },
                      on: { click: _vm.rebuildProject },
                    },
                    [_vm._v(" Rebuild ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "10" } },
            [
              _c("post-hits-button", {
                attrs: {
                  client: _vm.client,
                  credentials: _vm.credentials,
                  hitTypeInputs: _vm.hitTypeInputs,
                  hitInputs: _vm.hitInputs,
                  disabled: !(_vm.valid.HITType && _vm.valid.HIT),
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }